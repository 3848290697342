.contactform {
  position: relative; // Added this line
  display: flex;

  width: 100%;
  height: 100%;
  // Hamburger icon styles
  .hamburger-icon {
    display: none; // hidden by default
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    cursor: pointer;

    i {
      font-size: 24px;
    }
  }

  // Adjust layout when sidebar is open
  .with-sidebar {
    margin-left: 5px; // adjust based on your sidebar width
  }

  .contactformContainer {
    flex: 6;
    height: 100%;
    justify-content: center;

    .contactformBody {
      // 9/5/2023 -
      .success {
        color: green;
        text-align: center;
        align-items: center;
      }
      height: 100%;
      // Form scss.
      form {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .formGroup {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        input {
          width: 70vh; //700px;
          height: 45px;
          margin: 10px;
          padding: 2px;
          // Adjust font size of the text
          font-size: 15px;
        }

        textarea {
          width: 70vh; //705px;
          height: 100px;
          font-size: 15px;
          resize: vertical;
        }

        // 8/25/2023 - replaced input with button
        // 8/29/2023 - removed button styling as the button element was never used
      }
    }

    .titletext {
      color: #0071c5; // 8/29/2023 - changed from #6439ff;
      padding: 5px;
      text-align: center;
    }

    .p {
      color: red;
      text-align: center;
    }
  }

  // Mobile specific styles
  @media (max-width: 412px) {
    .hamburger-icon {
      display: block; // show the hamburger icon on mobile
    }

    // Sidebar styles (assuming there's a sidebar, but it's not in the snippet provided)
    .sidebar {
      position: absolute;
      top: 0;
      left: -100%; // initially hide off to the left
      width: 250px; // or any desired width for your sidebar
      height: 100%;
      transition: left 0.3s ease-in-out;

      &.open {
        left: 0; // show the sidebar when the "open" class is added
      }
    }
  }
}

// Adding responsive breakpoints
@media (max-width: 1200px) {
  .contactform {
    .hamburger-icon {
      display: block;
    }
    flex-direction: column;
    align-items: center;

    .contactformContainer {
      height: 100%;
      width: 100%;
      .contactformBody {
        height: 100vh; // 9/3/2023 used to fix a white section at the bottom
        form {
          height: 100%;
          input {
            width: 60vh; //700px;
          }

          textarea {
            width: 60vh; //705px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .contactform {
    .hamburger-icon {
      display: block;
    }
    .contactformContainer {
      height: 100%;

      .contactformBody {
        height: 100vh; // 9/3/2023 used to fix a white section at the bottom
        form {
          height: 100%;
          input {
            width: 40vh; //700px;
          }

          textarea {
            width: 40vh; //705px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .contactform {
    .hamburger-icon {
      display: block;
    }
    .contactformContainer {
      height: 100%;

      .contactformBody {
        height: 100vh; // 9/3/2023 used to fix a white section at the bottom
        form {
          height: 100%;
          input {
            width: 40vh; //700px;
          }

          textarea {
            width: 40vh; //705px;
          }
        }
      }
    }
  }
}

// /* 9/2/2023 - Media queries for sidebar and hamburger icon */
// @media (min-width: 769px) {
//   .hamburger-icon {
//     display: none !important;  // hide the hamburger icon
//   }
//   .sidebar {
//     display: block !important;  // show the sidebar
//   }
// }

// @media (max-width: 768px) {
//   .sidebar {
//     display: none !important;  // hide the sidebar
//   }
//   .hamburger-icon {
//     display: block !important;  // show the hamburger icon
//   }
// }

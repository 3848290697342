.list {
  display: flex;
  width: 100%;

  
  // Hamburger icon styles
  .hamburger-icon {
    display: none;  // hidden by default
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    cursor: pointer;
    
    i {
      font-size: 24px;
    }
  }
  
  
    // Adjust layout when sidebar is open
  .with-sidebar {
    margin-left: 5px;  // adjust based on your sidebar width
  }


  .listContainer {
    flex: 6;
    display: flex;
    flex-direction: column;
  }
}



// 9/3/2023

// Adding responsive breakpoints
@media (max-width: 1200px) {
  .list {
    .hamburger-icon {
      display: block;
    }
    flex-direction: column;
    align-items: center;

      .listContainer {
        height: 100%;
          width: 100%;
        
      }
  }
}

@media (max-width: 768px) {
  .list {
      .hamburger-icon {
        display: block;
      }
      .listContainer {
      }
  }
}

@media (max-width: 480px) {
  .list {
    .hamburger-icon {
      display: block;
    }
      .listContainer {
      }
  }
}

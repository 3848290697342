.single {
  display: flex;
  width: 100%;

  
  
  // Hamburger icon styles
  .hamburger-icon {
    display: none;  // hidden by default
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    cursor: pointer;
    
    i {
      font-size: 24px;
    }
  }
  
  
    // Adjust layout when sidebar is open
  .with-sidebar {
    margin-left: 5px;  // adjust based on your sidebar width
  }

  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        position: relative;

        .editButtonSingle {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          font-size: 12px;
          color: #80c8ff; //#7451f8;
          background-color: #4366ff18;//#7551f818;
          cursor: pointer;
          border-radius: 0px 0px 0px 5px;
        }

        .item {
          display: flex;
          gap: 20px;

          .itemImg {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }

          .details {
            .itemTitle {
              margin-bottom: 10px;
              color: #555;
            }

            .detailItem {
              margin-bottom: 10px;
              font-size: 14px;

              .itemKey {
                font-weight: bold;
                color: gray;
                margin-right: 5px;
              }

              .itemValue {
                font-weight: 300;
                white-space: pre-line; // 9/7/2023 - area entered in textareas will display on multiple lines
              }
            }
          }
        }
      }

      .right {
        flex: 2;
        .mapWidget {
          display: flex;
          height: 90vh;
          //padding: 5px 20px;
        }

      }

      .mostright {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        position: relative;
      }
    }

    // For the multiple image widget
    .middle,
    .middle-bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 50px; // adjusted padding to hold the widget
      margin: 10px 20px;
    }

    // For the table below
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
  }
}


// Adding responsive breakpoints
@media (max-width: 1200px) {
  .single {
    .hamburger-icon {
      display: block;
    }
      flex-direction: column;
      align-items: center;

      .singleContainer {
          width: 100%;
      }
  }
}

@media (max-width: 768px) {
  .single {
      .hamburger-icon {
        display: block;
      }
      .singleContainer {
          .top {
              flex-direction: column;
              align-items: center;

              .left, .right, .mostright {
                  width: 100%;
              }
          }

          
      }
  }
}

@media (max-width: 480px) {
  .single {
    .hamburger-icon {
      display: block;
    }
      .singleContainer {
          .top {
              padding: 10px;

              .left, .right, .mostright {
                  padding: 10px;
              }
          }
      }
  }
}





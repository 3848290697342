.sidebar {
  flex: 1;
  border-right: 0.5px solid rgb(230, 227, 227);
  min-height: 100vh;
  background-color: white;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      font-size: 20px;
      font-weight: bold;
      color: #0071c5; // 8/29/2023 - changed to blue from purple
    }
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }

  .center {
    padding-left: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 10px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        &:hover {
          background-color: #ece8ff;
        }

        .icon {
          font-size: 18px;
          color: #0071c5;
        }

        span {
          font-size: 13px;
          font-weight: 600;
          color: #888;
          margin-left: 10px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #0071c5; //#7451f8;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}

// Adding responsive breakpoints
@media (max-width: 1200px) {
  .sidebar {
    .top {
      margin-top: 50px;
      .logo {
        font-size: 80px;
      }
    }
    .center {
      padding-left: 10px;
      margin-top: 50px;
      ul {
        .title {
          font-size: 40px;
          margin-top: 30px;
          margin-bottom: 10px;
        }

        li {
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;

          &:hover {
            background-color: #ece8ff;
          }

          .icon {
            font-size: 72px;
            color: #0071c5;
          }

          span {
            font-size: 52px;
            font-weight: 600;
            color: #888;
            margin-left: 10px;
          }
        }
      }
    }

    .bottom {
      .colorOption {
        width: 80px;
        height: 80px;
      }
    }
  }
}

.app.dark {
  background-color: #111;
  color: rgb(156, 156, 156);

  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }

  // .table {
  //   background-color: #111;

  //   .tableCell {
  //     color: gray;
  //   }
  // }

  .navbar {
    color: #999;
    border-color: #333;

    .search {
      border-color: gray;
    }
  }

  .sidebar {
    background-color: #111;
    border-color: #333;

    .top {
      .logo {
        color: #0071c5; //#999;
      }
    }
    hr {
      border-color: #333;
    }

    ul {
      li {
        &:hover {
          background-color: #333;
        }

        .icon {
          color: #0071c5; //#999;
        }
      }
    }
  }

  .datatable {
    .datagrid {
      color: gray;
      border: none;

      // 9/10/2023 - very important - used to make the checkbox white in dark mode
      // Found out by using Browser tools and looking in the Styles window under Elements
      // .MuiCheckbox-root determines the color of the checkbox when it is unchecked
      .MuiCheckbox-root {
        color: white;
      }

      // 9/10/2023 - preserve blue color for checked checkbox
      .MuiCheckbox-colorPrimary.Mui-checked {
        color: #1976d2;
      }

      .viewButton,
      .editButton,
      .deleteButton,
      .cellWithStatus {
        color: #80c8ff; //gray;
        border: 1px solid #80c8ff; //none;
      }
    }
  }

  input,
  textarea {
    color: white;
    background-color: transparent;
  }

  // 9/29/2023 - login form fields are white when you log out in dark mode
  .loginFormField {
    color: white;
    background-color: white;
  }

  // 9/3/2023 - contact form submit button changes color in dark mode
  .submitInput {
    color: white;
    background-color: #0071c5;
  }

  // 8/29/2023 - added dark mode styling for the widget link text
  .widget {
    .link {
      color: #0071c5;
    }
  }

  // 9/3/2023 - error text is now the right color
  .error {
    .errorContainer {
      .subContainer {
        .belowtext {
          color: white;
        }
      }
    }
  }
}

.error {
  display: flex;

  // Hamburger icon styles
  .hamburger-icon {
    display: none;  // hidden by default
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    cursor: pointer;
    
    i {
      font-size: 24px;
    }
  }
  
  
    // Adjust layout when sidebar is open
  .with-sidebar {
    margin-left: 5px;  // adjust based on your sidebar width
  }

  .errorContainer {
    flex: 6;
    height: 100vh;
    
    .subContainer {
      height: 100vh;
      align-items: center;
      justify-content: center;
      display: block;
      margin: auto;
      flex-direction: column;

      .errortext {
        color: red;
        padding: 5px;
        text-align: center;
      }
  
      .belowtext {
        color: black;
        text-align: center;
      }
  
      .returnButton {
        display: block;
        margin-top: 10px;
        justify-content: center;
        align-items: center;
        text-align: center;
        
        button {
          border-radius: 10px;
          background-color: #1e9dff;
          color: white;
          width: 150px;
          padding: 10px;
          font-weight: bold;
          cursor: pointer;
          border: none;
          // On hover, change the BG color
          &:hover {
            background-color: #0071c5;
          }
        }
      }
    }


    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;

      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }
  }
}



// 9/3/2023 - Adding responsive breakpoints
@media (max-width: 1200px) {
  .error {
    .hamburger-icon {
      display: block;
    }
      flex-direction: column;
      align-items: center;

      .errorContainer {
        height: 100%;
          width: 100%;
          .errorBody {
            height: 100vh; // 9/3/2023 used to fix a white section at the bottom
            form {
              height: 100%;
              input {
                width: 60vh;//700px;
    
              }
    
              textarea {
                  width: 60vh;//705px;
              }
            }
          }

      }
  }
}

@media (max-width: 768px) {
  .error {
      .hamburger-icon {
        display: block;
      }
      .errorContainer {
          height: 100%;

          .errorBody{
            height: 100vh; // 9/3/2023 used to fix a white section at the bottom
            form {
              height: 100%;
              input {
                width: 40vh;//700px;
    
              }
    
              textarea {
                  width: 40vh;//705px;
              }
            }
          }

          
      }
  }
}

@media (max-width: 480px) {
  .error {
    .hamburger-icon {
      display: block;
    }
      .errorContainer {
        height: 100%;

          .errorBody {
            height: 100vh; // 9/3/2023 used to fix a white section at the bottom
            form {  
              height: 100%;
              input {
                width: 40vh;//700px;
    
              }
    
              textarea {
                  width: 40vh;//705px;
              }
            }

          }
      }
  }
}

.new {
  width: 100%;
  display: flex;

  // 9/3/2023 - Hamburger icon styles
  .hamburger-icon {
    display: none;  // hidden by default
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    cursor: pointer;
    
    i {
      font-size: 24px;
    }
  }
  
  
    // Adjust layout when sidebar is open
  .with-sidebar {
    margin-left: 5px;  // adjust based on your sidebar width
  }

  .newContainer {
    flex: 6;


    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: lightgray;
        font-size: 20px;
      }

      .left {
        flex: 1;
        text-align: center;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .right {
        flex: 2;

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-around;

          .formInput,
          .button {
            width: 40%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }

            // 9/7/2023
            textarea {
              width: 100%;//705px;
              height: 50px;
              font-size: 15px;
              resize: vertical;
            }
          }

          
          
          
          // 7/14/2023 - disable the button
          .button{
            display: flex;
            align-items: center;
            margin-top: 20px;
            margin-left: 300px;
    
            button {
              width: 150px;
              padding: 10px;
              border: none;
              background-color: #0071c5; // 8/29/2023 - changed from teal;
              color: white;
              font-weight: bold;
              cursor: pointer;
              margin-top: 10px;
    
              &:disabled{
                background-color: rgba(3, 25, 92, 0.322);//rgb(3, 92, 92, 0.322);
                cursor: not-allowed;
              }
            }
          }
          // End button styling
        } // end form styling
      } // end right styling
    }
  }
}




// 9/3/2023 -  Adding responsive breakpoints
@media (max-width: 1200px) {
  .new {
    .hamburger-icon {
      display: block;
    }
      flex-direction: column;
      align-items: center;

      .newContainer {
        height: 100vh;
          width: 100%;

          .bottom {
              .right {
                form {
                  flex-direction: column;
                  .formInput,
                  .button {
                    flex-direction: column;
                  }
    
                  .button {
                    flex-direction: column;
                    align-items: center;
                    margin: auto;
                    margin-top: 20px;
                  }
                }
              } 

          }
          
      }
  }
}

@media (max-width: 768px) {
  .new {
    //flex-direction: column;
      .hamburger-icon {
        display: block;
      }
      .newContainer {
          height: 100vh;    
          flex-direction: column;

          .bottom {
            height: 100vh;
            .right {
              
                form {
                  flex-direction: column;

                  .formInput,
                  .button {
                    flex-direction: column;
                  }
    
                  .button {
                    flex-direction: column;
                    align-items: center;
                    margin: auto;
                    margin-top: 20px;
                  }
                }
            }
          }
      }
  }
}

@media (max-width: 480px) {
  .new {
    .hamburger-icon {
      display: block;
    }
      .newContainer {
        height: 100vh;
        flex-direction: column;

        .bottom {
          height: 100vh;
          .right {
            height: 100vh;
              form {
                flex-direction: column;
                
                .formInput,
                .button {
                  flex-direction: column;
                }
  
                .button {
                  flex-direction: column;
                  align-items: center;
                  margin: auto;
                  margin-top: 20px;
                }
              }
          }
        }

      }
  }
}
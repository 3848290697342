.home {
  display: flex;

  // Hamburger icon styles
  .hamburger-icon {
    display: none;  // hidden by default
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    cursor: pointer;
    
    i {
      font-size: 24px;
    }
  }
  
  
    // Adjust layout when sidebar is open
  .with-sidebar {
    margin-left: 5px;  // adjust based on your sidebar width
  }

  .homeContainer {
    flex: 6;

    .widgets,
    .charts {
      display: flex;
      padding: 20px;
      gap: 20px;
    }

    .mapWidget {
      display: flex;
      gap: 20px;
      height: 50vh;
      padding: 5px 20px;
    }

    .charts {
      padding: 5px 20px;
    }

    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;

      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }
  }
}


// 9/3/2023

// Adding responsive breakpoints
@media (max-width: 1200px) {
  .home {
    .hamburger-icon {
      display: block;
    }
      flex-direction: column;
      align-items: center;

      .homeContainer {
        height: 100%;
          width: 100%;

      }
  }
}

@media (max-width: 768px) {
  .home {
      .hamburger-icon {
        display: block;
      }
      .homeContainer {
          .widgets, 
          .charts, 
          .mapWidget,
          .listContainer {
            flex-direction: column;
            padding: 10px;
          } 

          .mapWidget{
            height: 70vh;
          }

          .charts {
            height: 40vh;
          }
      }
  }
}

@media (max-width: 480px) {
  .home {
    .hamburger-icon {
      display: block;
    }
      .homeContainer {
        .widgets, 
          .charts, 
          .listContainer {
            padding: 10px;
          } 
      }
  }
}

.deleteModal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.501);
    display: flex;
    align-items: center;
    justify-content: center;

    // Inside the modal window
    .modal {

        width: 20vw;
        height: 10vh;
        background-color: #bbbbbb;
        padding: 50px;
        border-radius: 10px;
        position: relative;
        align-items: center;
        justify-content: center;


        .top {
            //background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                color: black;
                font-size: 13px;
            }
            padding: 5px;
        }
    
        .bottom {
            display: flex;
            padding: 20px;
            gap: 20px;
            align-items: center;
            padding: 10px;
            justify-content: center;

            .deleteButton {
                color: white;
                font-size: 15px;
                padding: 2px 5px;
                border-radius: 5px;
                //background-color: red;
                background-color: crimson;
                border: 1px dotted rgba(220, 20, 60, 0.6);
                cursor: pointer;

                &:hover {
                    background-color:rgb(221, 76, 105);  
                }
            }
            
            .cancelButton {
                color: white;
                font-size: 15px;
                padding: 2px 5px;
                border-radius: 5px;
                //background-color: green;
                background-color: rgb(29, 167, 22);
                border: 1px dotted rgba(182, 230, 10, 0.629);
                cursor: pointer;

                &:hover {
                    background-color:rgb(84, 191, 78, 22);  
                }
            }
        }

    }

}
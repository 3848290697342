.videos {
    position: relative;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .videowidgettitle {
        font-size: 1rem;
        color: #555;
        margin-bottom: 20px;
    }

    // Downsize the placeholder image for a video
    .placeholderImage {
        width: 15vh;
        border-radius: 10px;
    }

    // Size of the incident video
    .incidentVideo {
        width: 30vh;
    }
}
.map {
    flex: 2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    align-items: center;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: gray;

            .title {
                font-size: 16px;
                font-weight: 500;
            }
    }

    .featuredMap {
        width: 100%;
        height: 90%;
        //margin-left: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        
    }

  
}
// 8/26/2023 - CSS file

.imageslider {
    position: relative;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .slidertitle {
        font-size: 1rem;
        color: #555;
        margin-bottom: 20px;
    }

    // Downsize each image in the slideshow
    .image {
        width: 15vh;
        border-radius: 10px;
    }

    .left-arrow {
        position: absolute;
        top: 50%;
        left: 32px;
        font-size: 2rem;
        color: darkgray;
        z-index: 10;
        cursor: pointer;
        user-select: none;
      }

    .right-arrow {
        position: absolute;
        top: 50%;
        right: 32px;
        font-size: 2rem;
        color: darkgray;
        z-index: 10;
        cursor: pointer;
        user-select: none;
      }
    
    .slide{
        opacity: 0;
        transition-duration: 1s ease;
    }
    
    .slide.active {
        opacity: 1;
        transition-duration: 1s;
        transform: scale(1.08);
      }

}

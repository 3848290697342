.loginMain {
  height: 100vh;
  background-color: #555555;
  display: block;
  justify-content: center;
  align-items: center;
  .hero {
    display: block;
    justify-content: center;
    align-items: center;
    .heroImage {
      height: 400px;
      background-image: url("../../pn1b.jpg");
      background-size: cover;
      background-position: center;
      background-color: rgba(5, 5, 5, 0.5);
    }
    .heroText {
      height: 100px;
      background-color: rgba(5, 5, 5, 0.5);
      justify-content: center;
      align-items: center;
      display: flex;

      h1 {
        color: #0071c5;
        font-size: 50px;
      }
    }
  }
  .login {
    height: 50vh;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    // 8/14/2023 - import the background image

    form {
      background-color: #dce6ec;
      border-radius: 10px;
      padding: 20px 5px 20px 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 150px;

      input {
        width: 300px;
        height: 45px;
        margin: 10px;
        // Adjust font size of the text
        font-size: 17px;
        // width: 200px;
        //height: 30px;
        //margin: 10px;
      }

      .disclaimerText {
        width: 550px;
        height: 300px;
        overflow-y: auto;
        border: 1px solid #ccc;
        padding: 5px;
        background: white;
        margin-bottom: 10px;
      }

      // 7/25/2023 Styling select
      select {
        width: 309px;
        height: 48px;
        margin: 10px;
        font-size: 17px;
        // width: 208px;
        // height: 30px;
        // margin: 10px;
      }

      .loginCheckbox {
        align-items: center;
        width: 460px;
        display: flex;
        flex-direction: row;
        margin-left: 145px;
        .whiteLabel {
          color: black;
        }
      }

      // Login button
      button {
        // width: 200px;
        // height: 30px;
        width: 308px;
        height: 45px;
        margin: 10px;
        border: none;
        background-color: #0071c5;
        color: white;
        font-weight: bold;
        font-size: 25px;
        cursor: pointer;
      }

      // Error message text
      .errorText {
        font-style: 12px;
        color: red;
        margin-top: 10px;
      }
    }
  }
}

// Adding responsive breakpoints
@media (max-width: 1200px) {
  .loginMain {
    .hero {
      .heroImage {
        height: 800px;
      }
    }
    .login {
      form {
        width: 70vw;
        height: 35vh;

        input {
          width: 550px;
          height: 75px;
          margin: 10px;
          // Adjust font size of the text
          font-size: 30px;
        }

        .disclaimerText {
          width: 550px;
        }

        // 7/25/2023 Styling select
        select {
          width: 559px;
          height: 75px;
          margin: 10px;
          font-size: 30px;
        }

        button {
          width: 558px;
          height: 75px;
          margin: 10px;
          border: none;
          background-color: #0071c5;
          color: white;
          font-weight: bold;
          font-size: 30px;
          cursor: pointer;
        }
      }
      //height: 70vh;
    }
  }
}

// Adding responsive breakpoints
@media (max-width: 768px) {
  .loginMain {
    .hero {
      .heroImage {
        height: 600px;
      }
    }
    .login {
      form {
        width: 70vw;
        height: 30vh;

        input {
          width: 400px;
          height: 75px;
          margin: 10px;
          // Adjust font size of the text
          font-size: 25px;
        }

        .disclaimerText {
          width: 400px;
        }

        // 7/25/2023 Styling select
        select {
          width: 409px;
          height: 75px;
          margin: 10px;
          font-size: 25px;
        }

        button {
          width: 408px;
          height: 75px;
          margin: 10px;
          border: none;
          background-color: #0071c5;
          color: white;
          font-weight: bold;
          font-size: 25px;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .loginMain {
    .hero {
      .heroText {
        h1 {
          font-size: 40px;
        }
      }
      .heroImage {
        height: 400px;
      }
    }
    .login {
      //height: 70vh;
      form {
        .disclaimerText {
          width: 400px;
        }
      }
    }
  }
}

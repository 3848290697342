.aiwidget {
    position: relative;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

    .adtitle {
        font-size: 2rem;
        color: #555;
        margin-bottom: 20px;
    }

    // Downsize each image in the slideshow
    .image {
        width: 40vh;
        //height: 25vh;
        border-radius: 10px;
    }
}